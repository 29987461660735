import React, { useEffect, useContext } from 'react';

import ThemeContext from '~contexts/ThemeContext';
import ContentTypes from '~components/contentTypes';

const StoryblokEntry = (props) => {
  const { setThemeState } = useContext(ThemeContext);

  const prepareStory = (rawStory) => {
    const story = Object.assign({}, rawStory);
    story.content = JSON.parse(story.content);
    return story;
  };

  const story = prepareStory(props.pageContext.story);

  useEffect(() => {
    const globalLayout = prepareStory(props.pageContext.globalLayout);
    setThemeState(globalLayout?.content);
  }, []);

  return (
    <>
      {React.createElement(ContentTypes(story.content.component), {
        key: story.content._uid,
        blok: story.content,
        published_at: story.published_at,
      })}
    </>
  );
};

export default StoryblokEntry;
